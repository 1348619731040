import type { PropsWithChildren } from 'react'

import { Bounded } from 'components/Bounded'
import { Footer, Header } from 'components/Layouts/components'

import { IBaseLayoutProps } from '../types'

const MainLayout = ({
  navigation,
  settings,
  navigationFooter,
  children,
  boundedVariant,
  className
}: PropsWithChildren<IBaseLayoutProps>) => {
  if (!navigation || !settings || !navigationFooter) {
    return null
  }
  return (
    <div className="grid min-h-full w-full grid-flow-row grid-rows-[auto_1fr_auto] text-slate-900">
      <Header navigation={navigation} settings={settings} />
      <main className={className}>
        <Bounded variant={boundedVariant}>{children}</Bounded>
      </main>
      <Footer
        settings={settings}
        navigation={navigation}
        navigationFooter={navigationFooter}
      />
    </div>
  )
}

export default MainLayout
